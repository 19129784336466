import axios from 'axios'
import { Refresh } from '../api/index'
import { Message } from 'element-ui'
import qs from 'qs'
import global from './globals.js'
import VueCookies from 'vue-cookies'
//请求接口地址 延迟时间`
let isRefreshing = false //是否正在刷新Token的标记
let requests = [] //重试队列,每一项将是一个待执行的函数形式
axios.defaults.withCredentials = false //在跨域请求时，会携带用户凭证
let flag = true
const service = axios.create({
  withCredentials: false, //在跨域请求时，会携带用户凭证
})
const request = {
  get: async function (url, data, config) {
    let params = data
    config = config || {}
    config.paramsSerializer = function (p) {
      return qs.stringify(p, { arrayFormat: 'repeat' })
    }
    return await service.get(url, { params, ...config })
    // return resData.data
  },
  post: async function (url, data, config) {
    return await service.post(url, data, config)
    // return resData.data
  },
  postForm: async function (url, data, config) {
    data = qs.stringify(data)
    config = { headers: { 'Content-type': 'application/x-www-form-urlencoded' } }
    return await service.post(url, data, { config })
  },
  delete: async function (url, data, config) {
    return await service.delete(url, data, { config })
  },
}
export default request
//请求拦截
service.interceptors.request.use(async (config) => {
  config.withCredentials = false
  if (process.env.NODE_ENV != 'development' && flag) {
    let data = await axios.get('/appconfig.json')
    global.apiBaseURL = data.data.baseURL
    flag = false
  }
  if (!config.url.startsWith('http:') && !config.url.startsWith('https:')) {
    // config.url = config.url.replace(global.apiBaseURL, '')
    config.url = global.apiBaseURL + config.url
  }
  config.headers['Authorization'] = 'Bearer ' + (VueCookies.get('accessToken') || '') //所有请求头里面都把accessToken带上
  return config //把处理好的请求返回出去
})

// 响应拦截
service.interceptors.response.use(
  (res) => {
    return Promise.resolve(res.data)
  },
  (error) => {
    const config = error.config
    if (error.response.status == 401) {
      if (!isRefreshing) {
        isRefreshing = true
        let accessToken = VueCookies.get('accessToken') || ''
        let refreshToken = VueCookies.get('refreshToken') || ''
        return Refresh({ accessToken, refreshToken })
          .catch((error) => {
            window.location.hash = '#/login'
            return false
          })
          .then((resolve) => {
            if (!resolve.accessToken) {
              VueCookies.set('accessToken', '', 0)
              VueCookies.set('refreshToken', '', 0)
              window.location.hash = '#/login'
              return false
            }
            VueCookies.set('accessToken', resolve.accessToken, 0)
            VueCookies.set('refreshToken', resolve.refreshToken, 0)
            config.baseURL = ''
            config.headers['Authorization'] = 'Bearer ' + resolve.accessToken
            requests.forEach((cb) => cb('Bearer ' + resolve.accessToken))
            requests = []
            return Promise.resolve(service(config))
          })
          .finally(() => {
            isRefreshing = false
          })
      } else {
        return new Promise((resolve) => {
          requests.push((Token) => {
            config.baseURL = ''
            config.headers['Authorization'] = Token
            resolve(service(config))
          })
        })
      }
    } else if (error.response.status == 400) {
      if (error.response.data == '"登录已过期，请重新登录"') {
        window.location.hash = '#/login'
        return false
      } else {
        Message({ message: error.response.data, type: 'error' })
        return false
      }
    } else if (error.response.status >= 500 && error.response.status < 600) {
      Message({ message: '服务器开小差了', type: 'error' })
      return false
    } else {
      Message({ message: error.response.data, type: 'error' })
      return false
    }
  }
)
